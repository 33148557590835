import { Injectable, inject } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, CanActivateFn } from '@angular/router';
import { Observable, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { AccountService } from 'src/app/services/account/account.service';

@Injectable({
    providedIn: 'root'
  })
  class LogoutGuardService {
    constructor(private accountService: AccountService) {}
  
    canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
      return this.accountService.logoutActiveUser().pipe(
        map(() => true),  
        catchError(() => of(true)) 
      );
    }
  }
  
  export const LogoutGuard: CanActivateFn = (next: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
    return inject(LogoutGuardService).canActivate(next, state);
  };