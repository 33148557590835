import { Pipe, PipeTransform } from '@angular/core';
import * as CryptoJS from 'crypto-js';
const key = '2Q7)e9W/xZLKwl6IyYnHI,.D$;1Gk+';

@Pipe({name: 'lock'})
export class EncryptPipe implements PipeTransform {
  transform(value: string) {
    if (value) {
      return CryptoJS.AES.encrypt(value, key).toString();
    } else {
      return '';
    }
  }
}

@Pipe({name: 'unlock'})
export class DecryptPipe implements PipeTransform {
  transform(encrypted: string) {
    if (encrypted) {
      const decrypted = CryptoJS.AES.decrypt(encrypted, key);
      return decrypted.toString(CryptoJS.enc.Utf8);
    } else {
      return '';
    }
  }
}

declare global {
  // eslint-disable-next-line id-blacklist
  interface String {
    lock(): string;
    unlock(): string;
  }
}

String.prototype.lock = function(): string {
  return CryptoJS.AES.encrypt(this.toString(), key).toString();
};
String.prototype.unlock = function(): string {
  return CryptoJS.AES.decrypt(this.toString(), key).toString(CryptoJS.enc.Utf8);
};