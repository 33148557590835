import { Component, OnDestroy, OnInit} from '@angular/core';
import { Title } from '@angular/platform-browser';
import { MsalBroadcastService } from '@azure/msal-angular';
import {
  AuthenticationResult,
  EventMessage,
  EventType
} from '@azure/msal-browser';
import { Subject, filter, takeUntil } from 'rxjs';
import { AccountService } from './services/account/account.service'

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
  })
  export class AppComponent implements OnInit, OnDestroy  {
    title = 'Drink Redemption';
    private readonly destroying$ = new Subject<void>();

    //Constructor
    constructor(
      private msalBroadcastService: MsalBroadcastService,
      private titleService: Title,
      private accountService: AccountService) {
        titleService.setTitle(this.title);
    }

    ngOnInit(): void {
      this.msalBroadcastService.msalSubject$.pipe(
        filter((msg: EventMessage) => msg.eventType === EventType.LOGIN_SUCCESS),
        takeUntil(this.destroying$)
      ).subscribe((result: EventMessage) => {
        const payload = result.payload as AuthenticationResult;
        console.debug('Setting Active Account To:', payload.account.name);
        this.accountService.setActiveAccount(payload.account);
      });
    }

  ngOnDestroy(): void {
    this.destroying$.next(undefined);
    this.destroying$.complete();
  }
    
}