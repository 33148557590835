import { Injectable, NgZone } from '@angular/core';
import { Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class OnlineStatusService {
  private onlineStatusSubject: Subject<boolean> = new Subject<boolean>();

  constructor(private zone: NgZone) {
    this.initOnlineStatusListener();
  }

  getOnlineStatus(): Observable<boolean> {
    return this.onlineStatusSubject.asObservable();
  }

  private initOnlineStatusListener(): void {
    this.zone.runOutsideAngular(() => {
      window.addEventListener('online', () => {
        this.zone.run(() => {
          this.onlineStatusSubject.next(true);
        });
      });

      window.addEventListener('offline', () => {
        this.zone.run(() => {
          this.onlineStatusSubject.next(false);
        });
      });
    });
  }
}
