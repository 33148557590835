import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MsalGuardConfiguration, MsalInterceptorConfiguration } from '@azure/msal-angular';
import { InteractionType, IPublicClientApplication, PublicClientApplication } from '@azure/msal-browser';
import { ConfigurationResponse, AzureConfigData, ConfigData } from 'src/app/models/configurationResponse';

@Injectable({
  providedIn: 'root'
})
export class ConfigurationService {
    azureConfigData: AzureConfigData | null = null;
    configData: ConfigData | null = null;

    private msalInstanceFactory: IPublicClientApplication | null = null;
    private msalInterceptorConfigFactory: MsalInterceptorConfiguration | null = null;
    private msalGuardConfigFactory: MsalGuardConfiguration | null = null;
    
    constructor(private readonly httpClient: HttpClient) {
    }
    
    loadAzureConfiguration(): Promise<any> {
        return new Promise<any>((resolve, reject) => {
          this.httpClient.get<ConfigurationResponse>('/api/configuration')
            .subscribe({
                next: (config: ConfigurationResponse) => {
                    this.azureConfigData = config.AzureConfiguration;
                    this.configData = config.Configuration;
                    this.loadFunctionKey();
                    const azureAdDefaultScope = `${this.azureConfigData.clientId}/.default`;
                     // assign authorization config

                    this.msalInstanceFactory = new PublicClientApplication({
                      auth: {
                        clientId: this.azureConfigData.clientId,
                        authority: this.azureConfigData.authority,
                        knownAuthorities: [],
                        redirectUri: '/auth',
                        postLogoutRedirectUri: '/cardscan',
                        navigateToLoginRequestUrl: true
                      },
                      cache: {
                        cacheLocation: 'sessionStorage',
                        temporaryCacheLocation: "sessionStorage",
                        storeAuthStateInCookie: false,
                        secureCookies: false,
                        claimsBasedCachingEnabled: true,
                      }
                    });
                    const protectedResourceMap = this.buildProtectedResourceMap(this.azureConfigData.protectedResources, azureAdDefaultScope);

                    this.msalInterceptorConfigFactory = {
                      interactionType: InteractionType.Redirect,
                      protectedResourceMap
                    };
          
                    this.msalGuardConfigFactory = {
                      interactionType: InteractionType.Redirect,
                      authRequest: {
                        scopes: [`${this.azureConfigData.clientId}/.default`],
                        domainHint: this.azureConfigData.domainHint
                      },
                      loginFailedRoute: '/unauthorized', 
                      
                    };
                    resolve(config);
                },
                error: (error: any) => {
                    let errorMessage = 'Unknown error!';
                    if (error.error instanceof ErrorEvent) {
                      // Client-side errors
                      errorMessage = `Error: ${error.error.message}`;
                    } else {
                      // Server-side errors
                      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
                    }
                    console.error(errorMessage);
                    reject(error);
                }
            })
        });
    }

    loadFunctionKey(): void {
      if(this.configData!==null && this.configData.FunctionKey==null) {
        const key = localStorage.getItem('fkey');
        this.configData.FunctionKey = (key ? key.unlock() : null);
      }
    }

    getMsalInstanceFactory(): IPublicClientApplication | null {
      return this.msalInstanceFactory;
    }

    getMsalGuardConfigFactory(): MsalGuardConfiguration | null {
      return this.msalGuardConfigFactory;
    }
  
    getMsalInterceptorConfigFactory(): MsalInterceptorConfiguration | null{
      return this.msalInterceptorConfigFactory;
    }
  
    getAzureConfigData(): AzureConfigData | null{
      return this.azureConfigData;

    }

    getConfigData(): ConfigData | null {
      return this.configData;
    }

    private buildProtectedResourceMap(resourcesMap: string | null, azureAdDefaultScope: string): Map<string, string[]> {
      
      const protectedResourceMap = new Map<string, string[]>();
      //check for null or empty resourceMap.
      if (resourcesMap && resourcesMap !== undefined && resourcesMap.length > 0) {
        const resources: string[] = resourcesMap.split(';');
    
        resources.forEach(resource => {
          if (resource && resource.trim() !== '') {
            protectedResourceMap.set(resource.trim(), [azureAdDefaultScope]);
          }
        });
      }
    
      // Adding a default resource
      protectedResourceMap.set('https://graph.microsoft.com/v1.0/me', ['user.read']);
    
      return protectedResourceMap;
    }
    
}

