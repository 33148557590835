import { Component, OnInit, OnDestroy, HostListener } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription, filter, interval } from 'rxjs';
import { ScanStatus } from 'src/app/enums/scanstatus';
import { RedemptionService } from 'src/app/services/redemption/redemption.service';
import { ConfigurationService } from 'src/app/services/configuration/configuration.service';
import { OnlineStatusService } from 'src/app/services/online-status/online-status.service';
import { SwUpdate } from '@angular/service-worker';

@Component({
  selector: 'app-cardscan',
  templateUrl: './cardscan.component.html',
  styleUrls: ['./cardscan.component.scss']
})
export class CardScanComponent implements OnInit,OnDestroy {
  //public variables
  scanStatus: ScanStatus = ScanStatus.scan;
  siteNumber: string | undefined;
  readonly ScanStatus : typeof ScanStatus = ScanStatus; 
  isLoading = false;
  isOnline = true;
  healthCheckResult: boolean | undefined;
  healthCheckSubscription: Subscription;
  log: string[] = [];

  //private variables
  private inputBuffer: string[] = [];
  private clearBufferTimeout: any; 
  private lastKeyPressTime: number | null = null;
  private loadingSubscription: Subscription | undefined = undefined;
  private onlineStatusSubscription: Subscription | undefined;
  private retryTimerSubscription: Subscription | undefined;
  private checkOnlineTimerSubscription: Subscription | undefined;
  private versionUpdatesSubscription: Subscription | undefined;
  private retryDelay = Number(this.configService.configData?.RetryDelay); //wait 10 seconds between each queue item
  private maxRetries = Number(this.configService.configData?.MaxRetries);
  private onlineCheckPeriod = Number(this.configService.configData?.OnlineCheckPeriod);
  private retryminutes = Number(this.configService.configData?.RetryOfflineMinutes);
  

  constructor(private configService: ConfigurationService,
              private router: Router, 
              private redemption: RedemptionService,
              private onlineStatusService: OnlineStatusService,
              private swUpdate: SwUpdate) {
    
      //get store number for storage or empty string if missing
      const storedItem = localStorage.getItem('CurrentStore')
      this.siteNumber = storedItem ? storedItem : '';
      if(this.configService.configData?.FunctionKey===null) {
        console.debug("Function key NOT set.  Api calls will be rejected.");
      }
      if(this.retryminutes === undefined || this.retryDelay === undefined || this.maxRetries === undefined || this.onlineCheckPeriod === undefined) {
        throw new Error("Configuration values must be set!");
      }
      const retryMilliseconds = this.retryminutes * 60000; //convert to milliseconds
      console.debug(`Retry offline transactions will trigger every ${this.retryminutes} minutes.`);
      console.debug('SiteNum:'+this.siteNumber);
      //run and sub healthcheck
      redemption.healthCheck(this.siteNumber,false,false);
      this.healthCheckSubscription = this.redemption.result$.pipe(filter(result => result !== undefined)).subscribe(result => {
        this.healthCheckResult = result;
      });
      
      //subscribe to offline queue retry
      this.retryTimerSubscription = interval(retryMilliseconds).subscribe(() => {
        this.redemption.sync().subscribe({
          complete: () => {
            console.debug('Sync completed.');
          }
        });
      });  
      //setup interval to check if online
      console.debug(`Retry online health check will trigger every ${this.onlineCheckPeriod } seconds.`);
      this.checkOnlineTimerSubscription = interval(this.onlineCheckPeriod * 1000).subscribe(() => {
        if(this.isOnline) {
          this.redemption.healthCheck(this.siteNumber,false, false);
        }
      });  
      if (this.swUpdate.isEnabled) {
        this.versionUpdatesSubscription = this.swUpdate.versionUpdates.subscribe((e) => {
          if(e.type==='VERSION_READY') {
            if(this.isLoading===false) {
              window.location.reload();
            } else {
              // If isLoading is true, wait until it becomes false
            
              let retryCount = 0;
    
              const retryTimer = setInterval(() => {
                retryCount++;
                if (!this.isLoading) {
                  // If isLoading becomes false, reload
                  clearInterval(retryTimer);
                  window.location.reload();
                } else if (retryCount >= this.maxRetries) {
                  // If maximum retries reached, abort
                  clearInterval(retryTimer);
                  console.debug('Exceeded maximum retries. Aborting reload.');
                }
              }, this.retryDelay);
            }
          }
        });
      }
  } //end constructor
  
  
  @HostListener('document:keypress', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent) {
    //debug events to trigger screens, remove when read for QA
      
      //only accept numbers
     if (/^\d$/.test(event.key)) {
       this.inputBuffer.push(event.key);
       this.lastKeyPressTime = Date.now();
       clearTimeout(this.clearBufferTimeout);
       this.setupTimer();

       console.debug("buffer:"+this.inputBuffer.join('') + ' len:'+ this.inputBuffer.length);
       if(this.inputBuffer.length===14 && this.siteNumber !== undefined) {
          this.redemption.creditBurn(this.inputBuffer.join(''),this.siteNumber).subscribe((success) => {
            if(success) {
              this.scanStatus=ScanStatus.ok;
            } else {
              this.scanStatus=ScanStatus.error;
            }
            // Set a timeout to reset the scanStatus after 5 seconds of showing ok/error image
            setTimeout(() => {
              this.scanStatus = ScanStatus.scan;
              this.inputBuffer=[];
              clearTimeout(this.clearBufferTimeout);
            }, 5000);
          });
       }
     }

  }

  ngOnInit() {
    //subscribe to loading message
    this.loadingSubscription = this.redemption
    .getLoading()
    .subscribe((loading) => {
      this.isLoading = loading;
    });
    this.onlineStatusSubscription = this.onlineStatusService.getOnlineStatus().subscribe((isOnline) => {
      this.isOnline = isOnline;
      if(isOnline) {
        this.redemption.sync().subscribe({
          complete: () => {
            console.debug('Sync completed.');
          }
        });
        this.redemption.healthCheck(this.siteNumber,false, false);
      }
    });

    this.setupTimer()  //for clearing buffer on a timer
  }

  ngOnDestroy() {
    window.removeEventListener('keypress', this.handleKeyboardEvent);
    clearTimeout(this.clearBufferTimeout);
    if(this.loadingSubscription !==  undefined) {
      this.loadingSubscription.unsubscribe();
    }
    if(this.onlineStatusSubscription) {
      this.onlineStatusSubscription.unsubscribe();
    }
    if(this.retryTimerSubscription) {
      this.retryTimerSubscription.unsubscribe();
    }
    if(this.versionUpdatesSubscription !== undefined) {
      this.versionUpdatesSubscription.unsubscribe();
    }
    if(this.checkOnlineTimerSubscription !== undefined) {
      this.checkOnlineTimerSubscription.unsubscribe();
    }
    if(this.healthCheckSubscription) {
      this.healthCheckSubscription.unsubscribe();
    }
  }

  navigateSetup(): void {
    this.router.navigate(['/setup']); //go back to setup 
  }
  
  private setupTimer(): void {
    this.clearBufferTimeout = setTimeout(() => {
      const currentTime = Date.now();
      if (this.lastKeyPressTime && currentTime - this.lastKeyPressTime >= 10000) {
        this.inputBuffer = [];
        this.lastKeyPressTime = null;
        console.debug("Cleared keyboard buffer after 10 seconds");
      }
    }, 10000); // 10 seconds
  }

}
