import { Injectable } from '@angular/core';
import { MsalService } from '@azure/msal-angular';
import { AccountInfo, AuthenticationResult, IdTokenClaims } from '@azure/msal-common';
import { BehaviorSubject, Observable, catchError, from, map, of } from 'rxjs';
import { Token } from 'src/app/models/token';
import { ConfigurationService } from 'src/app/services/configuration/configuration.service';

@Injectable({
    providedIn: 'root'
})
export class AccountService  {


    activeUser$: Observable<AccountInfo | null>;
    userRoles$: Observable<string[]>;

    private currentStoreSubject: BehaviorSubject<AccountInfo | null>;
    private userRolesSubject: BehaviorSubject<string[]>;

    constructor(private msalService: MsalService, private configService: ConfigurationService) {
        this.currentStoreSubject = new BehaviorSubject<AccountInfo | null>(null); 
        const initialAccount = this.getActiveAccount(); // start with current active account
        this.userRolesSubject = new BehaviorSubject<string[]>(initialAccount?.idTokenClaims?.roles || []);
        this.activeUser$ = this.currentStoreSubject.asObservable();
        this.userRoles$ = this.userRolesSubject.asObservable();
    }


    setActiveAccount(account: AccountInfo): void {
        this.msalService.instance.setActiveAccount(account);
        //update active user and roles here
        this.currentStoreSubject.next(account);
        const roles = account?.idTokenClaims?.roles || [];
        this.userRolesSubject.next(roles);
    }

    //Can be called to update activeUser in this service assuming it is already set in msal
    updateAccount(): void {
        const activeAccount = this.getActiveAccount();
        this.currentStoreSubject.next(activeAccount);
        //get roles
        const roles = activeAccount?.idTokenClaims?.roles || [];
        this.userRolesSubject.next(roles);
    }

    logoutActiveUser(): Observable<boolean> {
        const activeAccount = this.getActiveAccount();
        if (activeAccount != null) {
          const logoutHint = activeAccount?.idTokenClaims?.login_hint;
          const logout$ = logoutHint !== undefined
            ? from(this.msalService.instance?.logoutRedirect({logoutHint, account: activeAccount }))
            : from(this.msalService.instance?.logoutRedirect({account: activeAccount}));
    
          return logout$.pipe(
            map(() => true), 
            catchError(() => of(false))  
          );
        } else {
          return of(false);  
        }
      }

    public getActiveAccount(): AccountInfo | null {
        return this.msalService.instance.getActiveAccount();
    }

    public getUserRoles(): string[] {
        const account= this.msalService.instance.getActiveAccount();
        return account?.idTokenClaims?.roles || [];
    }

    public getAccessToken(): Observable<string | undefined> {
        const accountInfo = this.msalService.instance.getActiveAccount();
        if (accountInfo === null) {
            return of(undefined);
        }

        return this.msalService.acquireTokenSilent({
            scopes: [`${this.configService.azureConfigData?.clientId}/.default`]
        }).pipe(
            map((tokenResult: AuthenticationResult | undefined) => {
                if (tokenResult !== undefined && tokenResult.accessToken) {
                    return tokenResult.accessToken;
                } else {
                    return undefined;
                }
            }),
            catchError(() => of(undefined))
        );
    }

    public async getUserToken(): Promise<Token | string> {
        let activeAccount = this.getActiveAccount();
        const msalInstance = this.configService.getMsalInstanceFactory();
        
        let token: Token;
        if(!activeAccount) {
          //find if any of the accounts are logged into this app
          const accounts = msalInstance?.getAllAccounts();
          if(accounts) {
            const signedInAccount = accounts.find(account => account?.idTokenClaims?.aud === this.configService.azureConfigData?.clientId);
            if(signedInAccount) {
                this.setActiveAccount(signedInAccount);
                activeAccount = signedInAccount;
            }
          }
        }
        
        if(activeAccount) {
            const claims:IdTokenClaims | undefined = activeAccount.idTokenClaims;
            const tokenResult: AuthenticationResult | undefined = await msalInstance?.acquireTokenSilent({
              scopes: [`${this.configService.azureConfigData?.clientId}/.default`]
            });
            if(tokenResult!==undefined) {
              if(tokenResult.accessToken) {
               token = {
                    name: claims?.name?? '',
                    emails : claims?.emails?? [],
                    roles: claims?.roles?? [],
                    accessToken: tokenResult.accessToken?? ''
               }
               return token;
              }
            } else {
              return "Missing Auth Token.";
            }
        }
        return "No accounts are logged in.";
    }
}