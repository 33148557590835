<div *ngIf="siteNumber===''; else content">
  <div class="notset-message">
    <h1>Location Must Be Set</h1>
    <button kendoButton [style.width.px]="'400px'"  [style.height.px]="'50px'" (click)="navigateSetup()">Setup Store</button>
  </div>
</div>

<ng-template #content>
  <div class="full-screen">
    <div class="k-loading-panel" *ngIf="isLoading===true">
      <div class="k-loading-panel-mask"></div>
      <div class="k-loading-panel-wrapper">
        <kendo-loader *ngIf="isLoading===true" [type]="'converging-spinner'" [themeColor]="'info'" [size]="'large'">
        </kendo-loader>
        <div class="k-loading-panel-text">Sending Data...</div>
      </div>
    </div>
      <div class="storesite" (click)="navigateSetup()"><span class="status-square" [ngClass]="{'online':isOnline && healthCheckResult,'offline':!isOnline || !healthCheckResult}"></span>#{{siteNumber}}</div>
    <div *ngIf="scanStatus === ScanStatus.scan">
      <img src="assets/_1_Start_Screen.jpg" alt="Scan Image">
    </div>
    <div *ngIf="scanStatus === ScanStatus.ok">
      <img src="assets/_3_Approved.jpg" alt="Transaction OK">
      <audio [src]="'assets/success.mp3'" autoplay></audio>
    </div>
    <div *ngIf="scanStatus === ScanStatus.error">
      <img src="assets/_4_Failure.jpg" alt="Error">
      <audio [src]="'assets/failure.mp3'" autoplay></audio>
    </div>
  </div>
</ng-template>