<ng-container>
    <div class="container">
      <header class="">
        <h1 class="title">Unauthorized</h1>
        <h2 class="strapline">
          You are not authorized to view the resource requested.
        </h2>
      </header>
    </div>
  </ng-container>
  