import { BrowserModule } from "@angular/platform-browser";
import { FormsModule } from "@angular/forms";
import { APP_INITIALIZER, NgModule, isDevMode } from "@angular/core";
import { HttpClientModule } from '@angular/common/http';
import { RouterModule, Routes } from '@angular/router';
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { ServiceWorkerModule } from '@angular/service-worker';
import {
  MSAL_GUARD_CONFIG,
  MSAL_INSTANCE,
  MSAL_INTERCEPTOR_CONFIG,
  MsalBroadcastService,
  MsalGuard,
  MsalGuardConfiguration,
  MsalInterceptorConfiguration,
  MsalModule,
  MsalRedirectComponent,
  MsalService
} from '@azure/msal-angular';
import { IPublicClientApplication } from '@azure/msal-browser';
import { ProgressBarModule } from "@progress/kendo-angular-progressbar";
import { DropDownListModule , DropDownsModule } from "@progress/kendo-angular-dropdowns";
import { ButtonsModule } from "@progress/kendo-angular-buttons";
import { InputsModule } from "@progress/kendo-angular-inputs";
import { LabelModule } from "@progress/kendo-angular-label";
import { IndicatorsModule } from "@progress/kendo-angular-indicators";
import { NotificationModule } from '@progress/kendo-angular-notification';



import { AppComponent } from './app.component';
import { CardScanComponent } from './components/cardscan/cardscan.component';
import { SetupComponent } from './components/setup/setup.component';
import { UnauthorizedComponent } from './components/unauthorized/unauthorized.component';
import { ConfigurationService } from './services/configuration/configuration.service';
import { RedemptionService } from "./services/redemption/redemption.service";
import { LogoutGuard } from "./guards/logout-guard";

const routes: Routes = [
  { path: '', redirectTo: 'cardscan', pathMatch: 'full'},
  { path: 'cardscan', component: CardScanComponent, canActivate: [LogoutGuard] },
  { path: 'unauthorized', component: UnauthorizedComponent },
  { path: 'setup', component: SetupComponent, canActivate: [MsalGuard]},
  { path: 'auth', component: MsalRedirectComponent }
];

export const loadAzureConfiguration = (configService: ConfigurationService) => (): Promise<any> =>
  configService.loadAzureConfiguration();

export const getMsalInstanceFactory = (configService: ConfigurationService): IPublicClientApplication | null =>
  configService.getMsalInstanceFactory();

export const getMsalGuardConfigFactory = (configService: ConfigurationService): MsalGuardConfiguration | null =>
  configService.getMsalGuardConfigFactory();

export const getMsalInterceptorConfigFactory = (configService: ConfigurationService): MsalInterceptorConfiguration | null =>
  configService.getMsalInterceptorConfigFactory();

@NgModule({
  declarations: [AppComponent, CardScanComponent, SetupComponent, UnauthorizedComponent],
  imports: [
    BrowserModule, 
    MsalModule,
    HttpClientModule, 
    BrowserAnimationsModule,
    ProgressBarModule,
    LabelModule,
    DropDownListModule,
    DropDownsModule,
    FormsModule,
    ButtonsModule,
    InputsModule,
    IndicatorsModule,
    NotificationModule,
    RouterModule.forRoot(routes),
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: !isDevMode(),
      // Register the ServiceWorker as soon as the application is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerImmediately'
    })],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: loadAzureConfiguration,
      deps: [ConfigurationService],
      multi: true
    },
    {
      provide: MSAL_INSTANCE,
      useFactory: getMsalInstanceFactory,
      deps: [ConfigurationService]
    },
    {
      provide: MSAL_GUARD_CONFIG,
      useFactory: getMsalGuardConfigFactory,
      deps: [ConfigurationService]
    },
    {
      provide: MSAL_INTERCEPTOR_CONFIG,
      useFactory: getMsalInterceptorConfigFactory,
      deps: [ConfigurationService]
    },  
    MsalService,
    MsalGuard,
    ConfigurationService,
    RedemptionService,
    MsalBroadcastService
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
