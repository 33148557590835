import { Injectable, TemplateRef, ViewContainerRef  } from '@angular/core';
import { NotificationService, NotificationRef  } from '@progress/kendo-angular-notification';

@Injectable({
  providedIn: 'root'
})
export class AppNotificationService {
  constructor(private readonly notificationService: NotificationService) {}

  //setting hideAfter to 0 will make the notification stay until the user closes it
  show(
    message: string | TemplateRef<any>,
    hideAfter: number = 3000,
    style: 'error' | 'none' | 'success' | 'warning' | 'info' | undefined = 'none',
    viewContainer: ViewContainerRef | undefined
  ): NotificationRef {
    const notificationRef = this.notificationService.show({
      content: message,
      animation: { type: 'slide', duration: 400 },
      position: { horizontal: 'center', vertical: 'top' },
      type: { style, icon: true },
      hideAfter,
      closable: hideAfter === 0 ? true : false,
      appendTo: viewContainer
    });

    if (notificationRef.notification && hideAfter > 0) {
      notificationRef.notification.instance.closable = true;
    }

    return notificationRef;
  }

  info(message: string | TemplateRef<any>, hideAfter: number = 3000, viewContainer: ViewContainerRef | undefined): NotificationRef {
    return this.show(message, hideAfter, 'info', viewContainer);
  }

  success(message: string | TemplateRef<any>, hideAfter: number = 3000, viewContainer: ViewContainerRef | undefined): NotificationRef {
    return this.show(message, hideAfter, 'success', viewContainer);
  }

  warning(message: string | TemplateRef<any>, hideAfter: number = 3000, viewContainer: ViewContainerRef | undefined): NotificationRef {
    return this.show(message, hideAfter, 'warning', viewContainer);
  }

  error(message: string | TemplateRef<any>, hideAfter: number = 3000, viewContainer: ViewContainerRef | undefined): NotificationRef {
    return this.show(message, hideAfter, 'error', viewContainer);
  }
}
